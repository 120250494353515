<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-21 15:52:16
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-29 14:05:10
 * @FilePath: /mediatom-web/src/viewsForManage/Flow/ConfigDetail/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="adplace-config-container">
    <GoBack @goBack="goBack" title1="流量管理" :title2="type === '1' ? '供应商' : '广告位'" title3="配置详情" />
    <ConfigLayout title1="预算列表" title2="配置列表">
      <Head :sourceCount="dataList.length" slot="head" @changeSupplier="changeSupplier" @changePlace="changePlace" />
      <LeftMain @addSource="addSource" slot="left" ref="leftMain" :checkedIdList="checkedIdList" />
      <div slot="right" class="main">
        <div class="main-head">
          <el-popover :disabled="selectIdList.length === 0" placement="bottom-start" :ref="'popover'">
            <div style="padding: 5px">
              <a-radio style="font-weight: bold; color: #333" :checked="true">状态</a-radio>
              <div style="display: flex; margin-top: 10px">
                <el-tooltip effect="dark" :disabled="selectIdList.length > 0" content="" placement="right-start">
                  <el-button plain class="btnHover" @click="handleBatch(handleBatchConfig)">利润配置</el-button>
                </el-tooltip>
                <el-tooltip effect="dark" :disabled="selectIdList.length > 0" content="" placement="right-start">
                  <el-button plain class="btnHover" @click="handleBatch(handleBatchDelete)">删除</el-button>
                </el-tooltip>
                <el-tooltip effect="dark" :disabled="selectIdList.length > 0" content="" placement="right-start">
                  <el-button
                    plain
                    class="btnHover"
                    @click="handleBatch(handleChangeSts, selectIdList, 'A')"
                  >开启</el-button
                  >
                </el-tooltip>
                <el-tooltip effect="dark" :disabled="selectIdList.length > 0" content="" placement="right-start">
                  <el-button
                    plain
                    class="btnHover"
                    @click="handleBatch(handleChangeSts, selectIdList, 'S')"
                  >关闭</el-button
                  >
                </el-tooltip>
                <el-tooltip effect="dark" :disabled="selectIdList.length > 0" content="" placement="right-start">
                  <el-button plain class="btnHover" @click="handleBatch(handleBatchCopy, selectIdList)">复制</el-button>
                </el-tooltip>
                <el-tooltip effect="dark" :disabled="selectIdList.length > 0" content="" placement="right-start">
                  <el-button plain class="btnHover" @click="handleBatch(handleBatchQps, selectIdList)">QPS</el-button>
                </el-tooltip>
                <el-tooltip effect="dark" :disabled="selectIdList.length > 0" content="" placement="right-start">
                  <el-button
                    plain
                    class="btnHover"
                    @click="handleBatch(frequencyControl, selectIdList)"
                  >频次控制</el-button
                  >
                </el-tooltip>
              </div>
            </div>
            <m-link-button
              slot="reference"
              :disabled="selectIdList.length === 0"
              class="btn"
              iconPos="left"
            ><BatchSvg
              slot="icon"
            /></m-link-button>
          </el-popover>
          <div class="right">
            <a-input
              @change="handleInput"
              class="search-item"
              style="width: 180px"
              placeholder="请输入广告源/广告源ID"
              v-model="searchquery.name"
            ></a-input>
            <m-select-more
              class="search-item"
              v-model="searchquery.platIdList"
              :allData="platList"
              :searchById="false"
              :hasIcon="false"
              :showId="false"
              label="广告平台"
              :showSelectedPart="true"
              :hasSearch="true"
              width="450px"
              :canSelectAll="true"
              :showLabel="false"
              @change="changePlat"
              :hasPlease="false"
            />
            <m-select-more
              class="search-item"
              v-model="searchquery.platAccountIdList"
              :allData="platAccountList"
              :searchById="false"
              :hasIcon="false"
              :showId="false"
              label="所属账号"
              :showSelectedPart="true"
              :hasSearch="true"
              width="450px"
              :showLabel="false"
              :canSelectAll="true"
              @change="initBindSource"
              :hasPlease="false"
            />
            <m-select-more
              class="search-item"
              v-model="searchquery.positionIdList"
              :allData="positionList"
              :searchById="false"
              :hasIcon="false"
              :showId="false"
              label="支持样式"
              :showSelectedPart="true"
              :hasSearch="false"
              width="450px"
              :canSelectAll="true"
              :showLabel="false"
              :hasPlease="false"
              @change="initBindSource"
            />
            <m-select-more
              class="search-item"
              v-model="searchquery.osList"
              :allData="[
                { id: '1', name: '安卓' },
                { id: '0', name: 'IOS' }
              ]"
              :searchById="false"
              :hasIcon="false"
              :showId="false"
              label="支持系统"
              :showSelectedPart="false"
              :hasSearch="false"
              width="220px"
              :canSelectAll="true"
              :showLabel="false"
              :hasPlease="false"
              @change="initBindSource"
            />
            <a-select
              placeholder="频次控制"
              allowClear
              v-model="searchquery.isFrequencyControl"
              class="search-item"
              @change="initBindSource"
            >
              <a-select-option :value="1">是</a-select-option>
              <a-select-option :value="0">否</a-select-option>
            </a-select>
            <a-button type="link" :icon="!isHidden ? 'down' : 'up'" @click="isHidden = !isHidden">{{
              !isHidden ? '更多' : '收起'
            }}</a-button>
          </div>
        </div>
        <div class="more-box">
          <template v-if="isHidden">
            <m-select-more
              class="search-item"
              v-model="searchquery.settleRatioList"
              :allData="settleRatioList"
              :searchById="false"
              :hasIcon="false"
              :showId="false"
              label="分成比例"
              :showSelectedPart="true"
              :hasSearch="true"
              width="450px"
              :canSelectAll="true"
              :showLabel="false"
              :hasPlease="false"
              @change="initBindSource"
            />
            <a-select placeholder="状态" allowClear v-model="sts" class="search-item">
              <a-select-option value="A">开启</a-select-option>
              <a-select-option value="S">关闭</a-select-option>
            </a-select>
          </template>
        </div>
        <a-spin :spinning="isLoading">
          <el-table
            :data="filterDataList"
            :row-key="(row) => row.id"
            style="border-bottom: none"
            @select-all="selectionChange"
            @selection-change="selectionChange"
          >
            <el-table-column align="left" type="selection" min-width="58"> </el-table-column>
            <el-table-column prop="name" label="广告源名称/ID" min-width="176">
              <div
                @click="handleSkipSource(row)"
                slot-scope="{ row }"
                style="width: 155px"
                class="name-row dsp"
                :class="{ open: row.dspSts === 'A' }"
              >
                <div class="name" :title="row.dspName">{{ row.dspName }}</div>
                <div class="id">
                  <div class="id-text" :title="row.platPlaceId">{{ row.platPlaceId }}</div>
                  <CopySvg class="copy-icon" @click.stop="copyId(row.platPlaceId)" />
                </div>
              </div>
            </el-table-column>
            <el-table-column prop="platName" label="广告源平台" min-width="110"></el-table-column>
            <el-table-column prop="settleRatio" label="分成比例" min-width="110">
              <template slot-scope="{ row }"> {{ row.settleRatio }}% </template>
            </el-table-column>
            <el-table-column prop="positionName" label="广告源样式" min-width="130"></el-table-column>
            <el-table-column prop="sts" label="状态">
              <template slot-scope="{ row }">
                <a-switch
                  size="small"
                  :checked="row.sts === 'A'"
                  @click="handleChangeSts([row.id], row.sts === 'A' ? 'S' : 'A')"
                ></a-switch>
              </template>
            </el-table-column>
            <el-table-column prop="strategySts" label="利润策略" min-width="116">
              <template slot-scope="{ row }">
                <template v-if="+sourceType || +row.sourceType">
                  <span v-if="row.strategySts === 'A'">已配置</span>
                  <span v-else style="color: red">未配置</span>
                </template>
                <template v-else>
                  {{ row.strategySts === 'A' ? '已配置' : '继承上级' }}
                </template>
              </template>
            </el-table-column>
            <el-table-column label="操作" min-width="160">
              <template slot-scope="{ row }">
                <span class="link-btn" @click="handleConfig(row)">利润配置</span>
                <span class="link-btn" @click="handleClickQPS(row)">QPS</span>
                <a-popover placement="bottom" :ref="`popover${row.id}`">
                  <a-icon type="more" class="link-btn" style="font-size: 18px; cursor: pointer" />
                  <div class="popover-btn-box" slot="content">
                    <div class="popover-btn" @click="handleFrequencyVisible(row)">频次控制</div>
                    <div class="popover-btn" @click="handleClickDeleteBtn(row)">删除</div>
                  </div>
                </a-popover>
              </template>
            </el-table-column>
          </el-table>
        </a-spin>
      </div>
    </ConfigLayout>
    <!-- 删除弹窗 -->
    <m-delete-modal
      :delVisible="delVisible"
      title="已配置广告源"
      @modalCancel="delVisible = false"
      @handleDelete="handleDeleteItem"
    />
    <m-delete-modal
      :hasCancle="false"
      :isDelete="false"
      :delVisible="hintVisible"
      title="温馨提示"
      @modalCancel="hintVisible = false"
      @handleDelete="() => (hintVisible = false)"
    >
      <template> 当前有{{ sum }}条广告源<span style="color: red">未配置</span>利润策略不能产生利润 </template>
    </m-delete-modal>
    <EditProfitModel
      :sourceType="sourceType"
      :visible="editProfitVisible"
      :isBatch="isBatch"
      :sourceInfo="sourceInfo"
      @modalCancel="editProfitVisible = false"
      :configType="type"
      :batchIdList="selectIdList"
      from="flow"
      @editSuccess="
        () => {
          editProfitVisible = false
          initBindSource()
        }
      "
    />
    <BatchCopySourceModal
      :visible="batchCopySourceVisible"
      @modalCancel="batchCopySourceVisible = false"
      :idList="selectIdList"
      :placeId="placeId"
      :supplierId="supplierId"
      :type="type"
    />
    <EditFlowStrategyModal
      :visible="qpsVisible"
      :strategyInfo="qpsInfo"
      :isBatch="isBatch"
      @modalCancel="qpsVisible = false"
      :qpsList="qpsList"
      @changeStrategy="
        () => {
          qpsVisible = false
          initBindSource()
        }
      "
    />
    <!-- 频次控制 -->
    <FrequencyModal
      :visible="frequencyVisible"
      :sourceInfo="sourceInfo"
      @modalCancel="frequencyVisible = false"
      @changeSource="initBindSource"
      :type="type"
      :dspIdList="dspIdList"
      :isBatch="isBatch"
    />
  </div>
</template>

<script>
import GoBack from '@/components/common/GoBack'
import ConfigLayout from '@/layouts/ConfigLayout.vue'
import BatchSvg from '@/assets/icons/batch.svg?inline'
import Head from './Header.vue'
import LeftMain from './LeftMain'
import { getPublisherDspLink, bindPublisherDsp, updatePublisherDspSts } from '@/apiForManage/flow/config'
import { getRtbPlatAccounts } from '@/apiForManage/common'
import CopySvg from '@/assets/icons/copy.svg?inline'
import { copy } from '@/utils/string'
import EditProfitModel from '../components/EditProfitModel'
import mixGetList from '@/mixins/getListForManage'
import BatchCopySourceModal from '../components/BatchCopySourceModal'
import EditFlowStrategyModal from '../components/EditFlowStrategyModal'
import FrequencyModal from '../components/frequencyModal'
import { mapState } from 'vuex'
const settleRatioList = [
  { name: '0-10', id: '0-10' },
  { name: '11-20', id: '11-20' },
  { name: '21-30', id: '21-30' },
  { name: '31-40', id: '31-40' },
  { name: '大于40', id: '40-100' }
]
for (let index = 0; index < 101; index++) {
  settleRatioList.push({ name: `${index}`, id: `${index}-${index}` })
}
export default {
  components: {
    GoBack,
    ConfigLayout,
    Head,
    LeftMain,
    CopySvg,
    EditProfitModel,
    BatchSvg,
    BatchCopySourceModal,
    EditFlowStrategyModal,
    FrequencyModal
  },
  mixins: [mixGetList],
  data () {
    return {
      isHidden: false,
      frequencyVisible: false,
      isLoading: false,
      dspIdList: [],
      type: undefined,
      // 返回的路由名
      routeName: undefined,
      supplierId: undefined,
      placeId: undefined,
      dataList: [],
      // 共享供应商未配置的广告源数量
      sum: 0,
      // 是否是平台供应商
      sourceType: undefined,
      // 提示弹框显示
      hintVisible: false,
      // 服务端已配置的广告源
      checkedIdList: [],
      // 列表选中广告源
      selectIdList: [],
      delVisible: false,
      // 需要删除的广告源id（单个删除使用）
      deleteIdList: [],
      editProfitVisible: false,
      // 需要编辑的广告源信息
      sourceInfo: {},
      // 是否批量编辑利润
      isBatch: false,
      searchquery: {
        positionIdList: [],
        osList: [],
        platAccountIdList: [],
        platIdList: [],
        settleRatioList: [],
        isFrequencyControl: undefined,
        name: undefined
      },
      sts: undefined,
      settleRatioList,
      // platList: [],
      platAccountList: [],
      timer: null,
      batchCopySourceVisible: false,
      qpsVisible: false,
      qpsList: [],
      qpsInfo: {}
    }
  },
  computed: {
    filterDataList () {
      if (!this.sts) {
        return this.dataList
      } else {
        return this.dataList.filter((item) => item.sts === this.sts)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      roleId: (state) => state.user.roleId,
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id
    })
  },
  async created () {
    this.getRouteQuery()
    await this.initBindSource()
    this.handleSourceType()
    this.getRtbPlatAccounts()
    this.getPlatListByuser()
    this.getPositionList()
  },
  methods: {
    handleSourceType () {
      if (this.roleId === 4 || this.role === 'SUPER_ADMIN') {
        return
      }
      if (this.sum) {
        // 平台供应商 且 有未开启利润配置的广告源
        this.hintVisible = true
      }
    },
    handleClickQPS (row) {
      this.isBatch = false
      this.qpsInfo = JSON.parse(JSON.stringify(row))
      this.qpsList = []
      this.qpsInfo.strategyType = this.type
      this.qpsInfo.placeId = this.placeId
      this.qpsInfo.publisherId = this.supplierId
      this.qpsVisible = true
    },
    handleSkipSource (row) {
      const url = this.$router.resolve({
        name: 'budgetsource',
        query: {
          dspId: row.dspPlaceId
        }
      })
      window.open(url.href, '_blank')
    },
    handleInput () {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.initBindSource()
      }, 1000)
    },
    async changeSupplier (id) {
      this.supplierId = id
      await this.initBindSource()
      this.handleSourceType()
      this.$nextTick(() => {
        this.$refs.leftMain.changeSupplier(id)
      })
    },
    changePlace (id) {
      this.placeId = id
      this.initBindSource()
    },
    async changePlat () {
      await this.getRtbPlatAccounts()
      this.initBindSource()
    },
    // 获取广告平台账户下拉
    async getRtbPlatAccounts () {
      const { data = [] } = await getRtbPlatAccounts({
        platIdList: this.searchquery.platIdList
      })
      this.platAccountList = data
    },
    handleBatch (callback, ...args) {
      callback.apply(this, args)
      this.$refs.popover.showPopper = false
    },
    handleBatchCopy () {
      this.batchCopySourceVisible = true
    },
    handleBatchQps () {
      this.isBatch = true
      this.qpsInfo = {
        strategyType: this.type,
        placeId: this.placeId,
        publisherId: this.supplierId
      }
      this.qpsList = this.dataList.filter((item) => this.selectIdList.includes(item.id)).map((item) => item.dspPlaceId)
      this.qpsVisible = true
    },
    handleBatchConfig () {
      this.isBatch = true
      this.editProfitVisible = true
    },
    handleDeleteItem () {
      this.handleChangeSts(this.deleteIdList, 'D')
      this.delVisible = false
    },
    handleBatchDelete () {
      this.deleteIdList = this.selectIdList
      this.delVisible = true
    },
    handleFrequencyVisible (row) {
      this.$refs[`popover${row.id}`].$children[0].sVisible = false
      this.sourceInfo = JSON.parse(JSON.stringify(row))
      this.frequencyControl([row.id], false)
    },
    frequencyControl (ids, isBatch = true) {
      this.isBatch = isBatch
      this.dspIdList = ids
      this.frequencyVisible = true
    },
    handleClickDeleteBtn (row) {
      this.$refs[`popover${row.id}`].$children[0].sVisible = false
      this.deleteIdList = [row.id]
      this.delVisible = true
    },
    handleConfig (row) {
      this.editProfitVisible = true
      this.sourceInfo = JSON.parse(JSON.stringify(row))
      this.isBatch = false
    },
    /**
     * @description: 已配置广告源状态修改 删除 开启 关闭
     * @param {*} ids 广告源id
     * @param {*} sts 广告源状态 D A S
     */
    handleChangeSts (ids, sts) {
      updatePublisherDspSts({
        type: this.type,
        ids: ids,
        publisherId: this.supplierId,
        sts
      }).then((res) => {
        const { code } = res
        if (code === 200) {
          this.$message.success(sts === 'D' ? '删除成功' : '修改成功')
          this.initBindSource()
        }
      })
    },
    // 复制方法
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    selectionChange (selection) {
      this.selectIdList = selection.map((item) => item.id)
    },
    async addSource (ids) {
      const { code } = await bindPublisherDsp({
        type: this.type,
        publisherId: this.supplierId,
        placeId: this.placeId,
        dspIdList: ids
      })
      if (code === 200) {
        this.$message.success('绑定成功')
        this.initBindSource()
      }
    },
    async initBindSource () {
      this.isLoading = true
      const { data = {} } = await getPublisherDspLink({
        publisherId: this.supplierId,
        placeId: this.placeId,
        type: this.type,
        ...this.searchquery
      })
      const { searchList = [], allList = [], sum = 0, sourceType = 0 } = data
      this.checkedIdList = allList.map((item) => item.dspPlaceId)
      this.dataList = searchList
      this.sum = sum
      this.sourceType = sourceType
      this.isLoading = false
    },
    getRouteQuery () {
      const { supplierId, placeId } = this.$route.query
      this.routeName = placeId ? 'adplace' : 'supplier'
      // 1 供应商 2 广告位
      this.type = placeId ? '2' : '1'
      this.supplierId = supplierId
      this.placeId = placeId
    },
    goBack () {
      this.$router.push({
        name: this.routeName,
        params: {
          ...this.$route.params
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.btnHover {
  border: none;
  box-shadow: none;
  &:hover {
    color: @primary-color;
    font-weight: 600;
  }
}
// 底部高度
@footHeight: 82px;
@name: adplace;
.@{name}-config-container {
  min-width: calc(1280px - 2 * @mediumMargin);
  margin: @mediumMargin;
  display: flex;
  justify-content: center;
  margin: calc(@mediumMargin + 62px) @mediumMargin @mediumMargin @mediumMargin;
  .main {
    display: flex;
    flex-direction: column;
    .main-head {
      display: flex;
      // justify-content: space-between;
      .right{
        display: flex;
        justify-content: flex-start;
        // overflow-x: auto;
        align-items: flex-start;
        flex-wrap: wrap;
        .search-item{
          width: 115px;
          margin-right: @compMargin;
          margin-bottom: 10px;
          &:last-child{
            margin: 0;
          }
        }
      }
    }
    .more-box{
      width: 100%;
      padding-bottom: 11px;
      border-bottom: 1px solid @compBorderColor;
      .search-item{
        width: 115px;
        margin-right: @compMargin;
        margin-bottom: 10px;
        margin-left: 10px;
      }
    }
    .name-row {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
      .id-text,
      .name {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .id {
        color: #858fa1;
        font-size: 12px;
        line-height: 17px;
        display: flex;
        .id-text {
          max-width: calc(100% - 30px);
        }
      }
      .copy-icon {
        cursor: pointer;
        min-width: 13px;
        min-height: 13px;
        margin-left: 6px;
      }
      &.dsp{
        .name::before{
          content: '';
          display: inline-block;
          width: 6px;
          height: 6px;
          background-color: @compDisabledColor;
          position: absolute;
          left: -10px;
          border-radius: 50%;
          top: 10px;
        }
        &.open .name::before{
          background-color: @assisColor;
        }
      }
    }
    .link-btn {
      color: @primary-color;
      margin-right: 12px;
      cursor: pointer;
    }
  }
}
::v-deep.@{name}-config-container{
  .el-table::before{
    background-color: #fff;
  }
}
.popover-btn-box{
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  .popover-btn{
    text-align: center;
    cursor: pointer;
    padding: 5px 0;
  }
}
</style>
