<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-24 14:50:56
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-10 17:41:43
 * @FilePath: /mediatom-web/src/viewsForManage/Flow/AdplaceConfig/LeftMain/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="left-main">
    <div class="left-input">
      <a-input v-model.trim="query.name" placeholder="请输入广告源/广告源ID" @change="changeInput" style="width:222px"></a-input>
      <a-dropdown placement="bottomCenter">
        <BarSvg class="sts-icon"/>
        <a-menu slot="overlay">
          <a-menu-item @click="changeSts('A')">
            <span :class="{'primary-text': query.sts === 'A'}" class="menu-item">已开启的广告源</span>
          </a-menu-item>
          <a-menu-item @click="changeSts('S')">
            <span :class="{'primary-text': query.sts === 'S'}" class="menu-item">已关闭的广告源</span>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
    <div class="left-head">
      <m-select-more
        class="search-item"
        v-model="query.platIdList"
        :allData="platList"
        :searchById="false"
        :hasIcon="false"
        :showId="false"
        label="广告平台"
        :showSelectedPart="true"
        :hasSearch="true"
        width="450px"
        :canSelectAll="true"
        :showLabel="false"
        @change="getRtbPlatAccounts"
        :hasPlease="false"
      />
      <m-select-more
        class="search-item"
        v-model="query.platAccountIdList"
        :allData="platAccountList"
        :searchById="false"
        :hasIcon="false"
        :showId="false"
        label="所属账号"
        :showSelectedPart="true"
        :hasSearch="true"
        width="450px"
        :showLabel="false"
        :canSelectAll="true"
        @change="getDspTeeList"
        :hasPlease="false"
      />
    </div>
    <a-button @click="handleAddSource" :disabled="disabled" type="primary" class="left-btn">添加到配置列表</a-button>
    <div class="account-list">
      <AccountCheckBox
        @checkedSourceForAccount="checkedSourceForAccount"
        :currentCheckedIdList="currentCheckedIdList"
        :checkedIdList="checkedIdList"
        v-for="account in treeList"
        :key="account.platAccountId"
        :accountInfo="account"/>
    </div>
  </div>
</template>

<script>
import { getRtbPlatAccounts } from '@/apiForManage/common'
import { getPnblisherDspTreeList } from '@/apiForManage/flow/config'
import AccountCheckBox from './AccountCheckBox'
import BarSvg from '@/assets/icons/bar.svg?inline'
import GetListMix from '@/mixins/getListForManage'
export default {
  name: 'LeftMain',
  components: { AccountCheckBox, BarSvg },
  mixins: [GetListMix],
  data () {
    return {
      query: {
        platIdList: [],
        platAccountIdList: [],
        name: undefined,
        sts: undefined,
        publisherId: undefined
      },
      // platList: [],
      platAccountList: [],
      treeList: [],
      currentCheckedIdList: [],
      timer: null
    }
  },
  props: {
    checkedIdList: {
      type: Array,
      default: () => ([])
    }
  },
  watch: {
    checkedIdList: {
      handler (newVal, oldVal) {
        if (oldVal && oldVal.every((item) => newVal.includes(item)) && newVal.length === oldVal.length) {
          return
        }
        this.currentCheckedIdList = [...newVal]
      },
      deep: true,
      immediate: true
    }
  },
  async created () {
    this.query.publisherId = this.$route.query.supplierId
    // this.getPlatList()
    await this.getPlatListByuser()
    await this.getRtbPlatAccounts()
  },
  computed: {
    disabled () {
      return this.currentCheckedIdList.length === this.checkedIdList.length
    }
  },
  methods: {
    changeInput () {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.getDspTeeList()
      }, 1000)
    },
    changeSts (e) {
      this.query.sts = e
      this.getDspTeeList()
    },
    handleAddSource () {
      // 将后续勾选的id列表抛出
      this.$emit('addSource', this.currentCheckedIdList.filter((id) => !this.checkedIdList.includes(id)))
    },
    // 传入所有选中id
    checkedSourceForAccount (ids) {
      this.currentCheckedIdList = ids
    },
    // 获取所有广告平台
    // async getPlatList () {
    //   const { data = [] } = await getRtbPlatformList()
    //   data.forEach((item) => {
    //     item.id = '' + item.id
    //   })
    //   this.platList = data
    //   this.getRtbPlatAccounts()
    // },
    // 获取广告平台账户下拉
    async getRtbPlatAccounts () {
      const { data = [] } = await getRtbPlatAccounts({
        platIdList: this.query.platIdList
      })
      this.platAccountList = data
      this.getDspTeeList()
    },
    async getDspTeeList () {
      const { data = [] } = await getPnblisherDspTreeList(this.query)
      this.treeList = data
    },
    changeSupplier (id) {
      this.query.publisherId = id
      this.getDspTeeList()
    }
  }
}
</script>

<style lang="less" scoped>
.left-main {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .left-input{
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .sts-icon{
      // border: 1px solid @compBorderColor;
      height: @compDefaultHeight;
      width: 36px;
      border-radius: 5px;
      // padding: 9px 1px 9px 1px;
      cursor: pointer;
      color: #fff;
    }
  }
  .left-head{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    border-bottom: 1px solid @compBorderColor;
    padding-bottom: 11px;
    .search-item{
      width: 127px;
    }
  }
  .left-btn{
    margin: 20px 0 14px 0;
  }
  .account-list{
    display: flex;
    flex-direction: column;
    position: relative;
  }
}
.menu-item{
  padding: 0 10px;
}
</style>
